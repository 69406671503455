<template>
  <div class="order-card-refund">
    <el-button type="text" size="medium" @click.stop="onRefund()"
      >退款</el-button
    >
    <!-- 编辑弹框 -->
    <el-dialog
      :title="editTitle"
      :visible.sync="editShow"
      :close-on-click-modal="false"
      append-to-body
      width="700px"
    >
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" inline>
        <el-descriptions :column="2">
          <el-descriptions-item label="卡券名称">{{
            orderInfo.cardName || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="购买日期">{{
            orderInfo.paymentDate || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="支付金额">{{
            (Number(orderInfo.paymentPrice) + Number(orderInfo.balanceAmount)).toFixed(2)
          }}</el-descriptions-item>
          <el-descriptions-item label="支付方式">{{
            orderInfo.paymentTypeName || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="已退金额">{{
            orderInfo.refundInfo
          }}</el-descriptions-item>
        </el-descriptions>
        <div class="inputBox" v-if="!isDisabled">
          <el-form-item
            :label="'微信退款(可退款金额：' + orderInfo.canRefundAmount + ')'"
            prop="wxRefundAmount"
            v-if="orderInfo.canRefundAmount != 0"
          >
            <el-input
              v-model.trim="params.wxRefundAmount"
              autocomplete="off"
              
              maxlength="16"
              @input="(v) => (params.wxRefundAmount = v.replace(/[^\d.]/g, ''))"
              placeholder="请输入微信退款金额"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="
              '本金退款(可退款金额：' + orderInfo.canRefundBalanceAmount + ')'
            "
            prop="bjRefundAmount"
            v-if="orderInfo.canRefundBalanceAmount != 0"
          >
            <el-input
              v-model.trim="params.bjRefundAmount"
         
              autocomplete="off"
              maxlength="16"
              @input="(v) => (params.bjRefundAmount = v.replace(/[^\d.]/g, ''))"
              placeholder="请输入本金退款金额"
            ></el-input>
          </el-form-item>
        </div>
        <section class="form-footer">
          <el-button
            type="info"
            plain
            disabled
            v-if="
              orderInfo.canRefundAmount == 0 &&
              orderInfo.canRefundBalanceAmount == 0
            "
            >已退款</el-button
          >
          <el-button :type="isDisabled?'info':'primary'" :disabled="isDisabled" @click="onSubmit('form-ref')" v-else
            >确认退款</el-button
          >
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { rules } from "@/db/rules";
import { getOrderInfo, getOrderRefund } from "@/api/card/order";
export default {
  props: ["orderId"],
  data() {
    return {
      // 弹框
      editShow: false, //弹框开关
      editTitle: "卡券退款", //弹框标题
      // from表单
      rules,
      params: {
        wxRefundAmount: "",
        bjRefundAmount: "",
      },
      orderInfo: "",
      isDisabled:false
    };
  },
  methods: {
    // 判断是否大于可退款金额
    goCheckRefundMoney() {
      if (this.params.wxRefundAmount > this.orderInfo.canRefundAmount) {
        return 1;
      } else if (
        this.params.bjRefundAmount > this.orderInfo.canRefundBalanceAmount
      ) {
        return 2;
      } else {
        return 5;
      }
    },
    // 【请求】订单详情
    getOrderInfo() {
      let data = {
        orderId: this.orderId,
      };
      getOrderInfo(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.orderInfo = res.data;
          this.isDisabled=[2,3,4].includes(Number(this.orderInfo.paymentType))
        }
      });
    },

    // 【请求】卡券退款
    getOrderRefund() {
      let data = {
        orderId: this.orderId,
        refundPrice: this.params.wxRefundAmount,
        balanceRefundAmount: this.params.bjRefundAmount,
      };
      getOrderRefund(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.$emit("reload");
        }
      });
    },

    // 【监听】退款
    onRefund() {
      this.params = {
        wxRefundAmount: "",
        bjRefundAmount: "",
      };
      this.getOrderInfo();
      this.editShow = true;
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let errType = this.goCheckRefundMoney();
          switch (errType) {
            case 1:
              this.$message({
                message: "微信退款金额超出最大可退款金额",
                type: "error",
              });
              break;
            case 2:
              this.$message({
                message: "本金退款金额超出最大可退款金额",
                type: "error",
              });
              break;
            case 5:
              this.getOrderRefund();
              break;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-card-refund {
  display: inline-flex;
  margin-left: 0.1rem;
}
.inputBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 48%;
    .el-input {
      width: 240px;
    }
  }
}
.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>